import { createAction, createActionGroup, props } from '@ngrx/store';
import { TeamData } from 'src/core/interfaces/team/team.interface';
import { NGRXTeamSource, NGRXTeamType } from './team-action.types';

export const TeamsActions = createActionGroup({
  source: NGRXTeamSource.TeamSource,
  events: {
    [NGRXTeamType.loadTeamsFromDB]: props<{
      teams: TeamData[];
      fetched: boolean;
    }>(),

    [NGRXTeamType.createTeam]: props<{ team: TeamData }>(),
    [NGRXTeamType.loadTeams]: props<{ teams: TeamData[] }>(),
    [NGRXTeamType.updateTeam]: props<{ updatedTeam: TeamData }>(),
    [NGRXTeamType.deleteTeam]: props<{ teamId: string }>(),
  },
});

export const initTeamStore = createAction(NGRXTeamType.initTeamStore);

export const removeAllTeams = createAction(NGRXTeamType.logOut);
