<div class="warning-wrapper d-flex-center2">
  <img src="/assets/screens/cat/navigation-failure.jpg" alt="broken link" />

  <h4>{{ invalidLinkData.title }}</h4>
  <p>
    {{ invalidLinkData.message }}
    <br />
    <button class="m-top-30px" mat-button mat-stroked-button (click)="goHome()">
      <mat-icon>sentiment_dissatisfied</mat-icon>
      Ok, go home
    </button>
  </p>
</div>
