export enum NGRXFeedbackType {
  setFeedbackFormData = 'Set Feedback Form Data',
  updateFeedbackFormData = 'Update Feedback Form Data',
  setRecipients = 'Set Recipients',
  setRequestAbout = 'Set Request About',

  removeAll = 'Remove All',
}

export enum NGRXFeedbackSource {
  FeedbackSource = 'Feedback Source',
}
