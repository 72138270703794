export enum NGRXTeamType {
  initTeamStore = 'Init Team Store',
  loadTeamsFromDB = 'Load Teams From DB',

  loadTeams = 'Load Teams',
  createTeam = 'Create Team',
  updateTeam = 'Update Team',
  updateTeamField = 'Update Team Field',
  deleteTeam = 'Delete Team',

  logOut = 'Log Out',
}

export enum NGRXTeamSource {
  TeamSource = 'Team Source',
}
