import { createAction, props } from '@ngrx/store';
import { NGRXOrganizationType } from './organization-action.types';
import { OrganizationState } from './organization.reducers';

export const loadOrganization = createAction(
  NGRXOrganizationType.loadOrganization,
  props<{ organization: OrganizationState }>(),
);

export const getAllEmployees = createAction(
  NGRXOrganizationType.getAllEmployees,
);

export const removeAllEmployees = createAction(NGRXOrganizationType.logOut);
