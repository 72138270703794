import {
  Directive,
  Input,
  OnChanges,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Permission } from '../enums/permissions.enum';

@Directive({
  selector: '[ifPermission]',
})
export class IfPermissionsDirective implements OnChanges {
  @Input() ifPermission: Permission[];
  @Input() ifPermissionElse: TemplateRef<any>;

  constructor(
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<any>,
  ) {}

  ngOnChanges() {
    const permissions = AuthService.getUser()?.permissions || [];
    let hasPermission = true;

    for (const permission of this.ifPermission) {
      if (!permissions.includes(permission)) {
        hasPermission = false;
        break;
      }
    }

    this.viewContainerRef.clear();

    if (hasPermission) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    } else if (this.ifPermissionElse) {
      this.viewContainerRef.createEmbeddedView(this.ifPermissionElse);
    }
  }
}
