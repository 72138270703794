export enum NGRXEventType {
  loadSchedulerEvents = 'Load Scheduler Events',
  addEvent = 'Add event',
  updateEvent = 'Update event',
  removeEvent = 'Remove Event',
  removeAll = 'Remove All',
}

export enum NGRXEventSource {
  SchedulerView = 'Scheduler View',
}
