import { Inject, Injectable } from '@angular/core';
import {
  MSAL_GUARD_CONFIG,
  MsalGuardConfiguration,
  MsalService,
} from '@azure/msal-angular';
import { AuthenticationResult, PopupRequest } from '@azure/msal-browser';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MicrosoftSSOService {
  private isAuthenticated = false;

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalAuthService: MsalService,
  ) {}

  isMSAuthenticated() {
    return this.msalAuthService.instance.getAllAccounts().length > 0;
  }

  login(): Observable<AuthenticationResult> {
    return this.msalAuthService.loginPopup({
      ...this.msalGuardConfig.authRequest,
    } as PopupRequest);
  }

  logout() {
    // Add log out function here
    this.msalAuthService.logoutPopup({
      mainWindowRedirectUri: '/',
    });
  }

  public setAuthStatus(isAuthenticated: boolean) {
    this.isAuthenticated = isAuthenticated;
  }

  public getAuthStatus() {
    return this.isAuthenticated;
  }
}
