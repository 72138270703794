enum Errors {
  POST = 'Failed to save record',
  GET = 'Failed to receive',
  SignUp = 'Failed to sign up',
  LogIn = 'Failed to log in',
  ResetPassword = 'Failed to reset password',
  ForgotPassword = 'Failed to send password reset email',
}

enum Status {
  error = 'Error',
  loading = 'Loading',
  pending = 'Pending',
  success = 'Success',
}

export { Errors, Status };
