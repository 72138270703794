import { createAction, createActionGroup, props } from '@ngrx/store';
import { Meeting } from 'src/core/interfaces/meeting.interface';
import { NGRXMeetingSource, NGRXMeetingType } from './meetings-action.types';

export const MeetingActions = createActionGroup({
  source: NGRXMeetingSource.MeetingSource,
  events: {
    [NGRXMeetingType.loadMeetings]: props<{ meetings: Meeting[] }>(),
    [NGRXMeetingType.addMeeting]: props<{ meeting: Meeting }>(),
    [NGRXMeetingType.setSelectedMeeting]: props<{ meetingId: string }>(),
    [NGRXMeetingType.updateMeeting]: props<{ meeting: Meeting }>(),
    [NGRXMeetingType.removeMeeting]: props<{ meetingId: string }>(),
    [NGRXMeetingType.removeMeetingQuestion]: props<{
      meetingId: string;
      meetingQuestionId: string;
    }>(),
  },
});

export const removeAllMeetings = createAction(NGRXMeetingType.removeAll);
