import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private apiURL: string = environment.apiURL;

  constructor(protected http: HttpClient) {}

  protected endpointBuilder(...args: string[]): string {
    return `${this.apiURL}/${args.join('/')}`;
  }
}
