import { createAction, createActionGroup, props } from '@ngrx/store';
import {
  FeedbackReport,
  FeedbackTemplateData,
} from 'src/core/interfaces/feedback.interface';
import {
  NGRXFeedbackTemplateSource,
  NGRXFeedbackTemplateType,
} from './feedback-template-action.types';

export const FeedbackTemplateActions = createActionGroup({
  source: NGRXFeedbackTemplateSource.FeedbackTemplateSource,

  events: {
    // Effects triggers
    [NGRXFeedbackTemplateType.createTemplateInDB]: props<{
      template: FeedbackTemplateData;
    }>(),
    [NGRXFeedbackTemplateType.loadTemplatesFromDB]: props<{
      templates: FeedbackTemplateData[];
    }>(),
    [NGRXFeedbackTemplateType.updateTemplateInDB]: props<{
      template: FeedbackTemplateData;
    }>(),
    [NGRXFeedbackTemplateType.deleteTemplateInDB]: props<{
      templateId: string;
    }>(),
    [NGRXFeedbackTemplateType.downloadReport]: props<{
      report: FeedbackReport;
    }>(),

    // Regular actions
    [NGRXFeedbackTemplateType.addTemplate]: props<{ templateId: string }>(),
    [NGRXFeedbackTemplateType.updateTemplate]: props<{
      template: FeedbackTemplateData;
    }>(),
    [NGRXFeedbackTemplateType.updateTemplateTitle]: props<{ title: string }>(),
    [NGRXFeedbackTemplateType.selectTemplate]: props<{ templateId: string }>(),
    [NGRXFeedbackTemplateType.deleteTemplate]: props<{ templateId: string }>(),
  },
});

export const initFeedbackTemplatesStore = createAction(
  NGRXFeedbackTemplateType.initFeedbackTemplatesStore,
);

export const downloadSuccessful = createAction(
  NGRXFeedbackTemplateType.downloadSuccessful,
);

export const removeAllFeedbackTemplates = createAction(
  NGRXFeedbackTemplateType.removeAll,
);
