export enum NGRXFeedbackTemplateType {
  initFeedbackTemplatesStore = 'Init Feedback Templates Store',

  createTemplateInDB = 'Create Template In DB',
  loadTemplatesFromDB = 'Load Templates From DB',
  updateTemplateInDB = 'Update Template In DB',
  deleteTemplateInDB = 'Delete Template In DB',
  downloadReport = 'Download Report',

  addTemplate = 'Add Template',
  updateTemplate = 'Update Template',
  updateTemplateTitle = 'Update Template Title',
  selectTemplate = 'Select Template',
  deleteTemplate = 'Delete Template',

  downloadSuccessful = 'Download Successful',
  removeAll = 'Remove All',
}

export enum NGRXFeedbackTemplateSource {
  FeedbackTemplateSource = 'Feedback Template Source',
}
