import { createAction, createActionGroup, props } from '@ngrx/store';
import { CalendarEvent } from 'src/core/interfaces/event.interface';
import { NGRXEventSource, NGRXEventType } from './calendar-event-action.types';

export const SchedulerActions = createActionGroup({
  source: NGRXEventSource.SchedulerView,
  events: {
    [NGRXEventType.loadSchedulerEvents]: props<{ events: CalendarEvent[] }>(),
    [NGRXEventType.addEvent]: props<{ event: CalendarEvent }>(),
    [NGRXEventType.updateEvent]: props<{ event: CalendarEvent }>(),
    [NGRXEventType.removeEvent]: props<{ eventId: string }>(),
  },
});

export const schedulerActionRemoveAll = createAction(NGRXEventType.removeAll);
