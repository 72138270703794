export enum NGRXMeetingType {
  loadMeetings = 'Load Meetings',
  addMeeting = 'Add Meeting',
  setSelectedMeeting = 'Set Selected Meeting',
  updateMeeting = 'Update Meeting',
  removeMeeting = 'Remove Meeting',
  removeMeetingQuestion = 'Remove Meeting Question',
  removeAll = 'Remove All',
}

export enum NGRXMeetingSource {
  MeetingSource = 'Meeting Source',
}
