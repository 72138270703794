import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, tap } from 'rxjs';
import { Endpoints } from 'src/core/enums/api/api-endpoints.enum';
import {
  ShortTeamData,
  TeamData,
  TeamMember,
} from 'src/core/interfaces/team/team.interface';
import { ApiService } from 'src/core/services/api.service';
import { TeamsActions } from 'src/core/store/team/team.actions';

@Injectable({
  providedIn: 'root',
})
export class TeamApiService extends ApiService {
  constructor(
    protected override http: HttpClient,
    private store: Store,
  ) {
    super(http);
  }


  /* CREATE */
  public createTeam(team: ShortTeamData): Observable<TeamData> {
    return this.http.post<TeamData>(
      this.endpointBuilder(Endpoints.Team.create),
      team,
    );
  }


  /* READ */
  public getTeamMembers(): Observable<TeamData[]> {
    return this.http.get<TeamData[]>(
      this.endpointBuilder(Endpoints.Team.teamMembers),
    );
  }

  public getAllUsers(
    options: {
      searchString?: string;
      participants?: string[];
    } = {
      searchString: '',
      participants: [],
    },
  ): Observable<{
    users: TeamMember[];
    totalCount: number;
  }> {
    return this.http.post<{
      users: TeamMember[];
      totalCount: number;
    }>(this.endpointBuilder(Endpoints.Team.getAllUsers), options);
  }

  public getUsersCount() {
    return this.http.get<number>(
      this.endpointBuilder(Endpoints.Team.usersCount),
    );
  }

  /* UPDATE */
  public updateTeam(team: TeamData): Observable<TeamData> {
    return this.http.post<TeamData>(
      this.endpointBuilder(Endpoints.Team.update),
      team,
    );
  }

  /* DELETE */
  public deleteTeam(teamId: string): Observable<TeamData> {
    return this.http
      .post<TeamData>(this.endpointBuilder(Endpoints.Team.delete), { teamId })
      .pipe(
        tap(() => {
          this.store.dispatch(TeamsActions.deleteTeam({ teamId }));
        }),
      );
  }
}
