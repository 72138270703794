import { createAction, createActionGroup, props } from '@ngrx/store';
import {
  ActionItem,
  Resource,
  Skill,
  SubtaskState,
  UserGoal,
} from 'src/core/interfaces/user/goal.interface';
import {
  NGRXActionItemType,
  NGRXGoalsSource,
  NGRXGoalsType,
  NGRXSubtaskType,
} from './goals-action.types';

export const GoalsActions = createActionGroup({
  source: NGRXGoalsSource.GoalsComponent,
  events: {
    [NGRXGoalsType.loadGoalsFromDb]: props<{
      goals: UserGoal[];
    }>(),

    [NGRXGoalsType.addGoal]: props<{ goal: UserGoal }>(),
    [NGRXGoalsType.addSkills]: props<{ goalId: string; skills: Skill[] }>(),
    [NGRXGoalsType.addResources]: props<{
      entityId: string;
      resources: Resource[];
    }>(),
    [NGRXGoalsType.loadGoals]: props<{ goals: UserGoal[] }>(),
    [NGRXGoalsType.updateGoal]: props<{ updatedGoal: UserGoal }>(),
    [NGRXGoalsType.updateField]: props<{
      entityId: string;
      updatedField: {
        field: string;
        value: unknown;
      };
    }>(),
    [NGRXGoalsType.setGoalState]: props<{
      goalId: string;
      completed: boolean;
    }>(),
    [NGRXGoalsType.deleteEntity]: props<{
      entityId: string;
    }>(),

    [NGRXGoalsType.createActionItem]: props<{
      actionItem: ActionItem;
      goalId: string;
    }>(),
    [NGRXGoalsType.updateActionItem]: props<{
      actionItem: ActionItem;
      goalId: string;
    }>(),
    [NGRXGoalsType.removeActionItem]: props<{
      actionItemName: string;
      goalId: string;
    }>(),

    [NGRXGoalsType.removeResource]: props<{
      resourceId: string;
      entityId: string;
    }>(),

    [NGRXGoalsType.removeContributor]: props<{
      entityId: string;
      userId: string;
    }>(),
  },
});

export const SubtaskActions = createActionGroup({
  source: NGRXGoalsSource.Subtasks,
  events: {
    [NGRXSubtaskType.add]: props<{
      goalId: string;
      actionItemId: string;
      subtask: {
        _id: string;
        title: string;
        state: SubtaskState;
      };
    }>(),
    [NGRXSubtaskType.delete]: props<{
      goalId: string;
      actionItemId: string;
      _id: string;
    }>(),
    [NGRXSubtaskType.updateField]: props<{
      goalId: string;
      actionItemId: string;
      _id: string;
      field: string;
      value: any;
    }>(),
  },
});

export const ActionItemActions = createActionGroup({
  source: NGRXGoalsSource.ActionItems,
  events: {
    [NGRXActionItemType.createOne]: props<{
      actionItem: ActionItem;
      goalId: string;
    }>(),
    [NGRXActionItemType.updateOne]: props<{
      actionItem: ActionItem;
      goalId: string;
    }>(),
    [NGRXActionItemType.removeOne]: props<{
      actionItemName: string;
      goalId: string;
    }>(),
  },
});

export const initGoalsStore = createAction(NGRXGoalsType.initGoalsStore);

export const removeAllGoals = createAction(NGRXGoalsType.logOut);
