import { Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-navigate',
  standalone: true,
  imports: [RouterModule],
  templateUrl: './navigate.component.html',
})
export class NavigateComponent {
  @Input() navigateTo: string;
}
