export enum FeedbackAction {
  request = 'request',
  give = 'give',
  takePRSurvey = 'take-pr-survey',
  rewindPRSurvey = 'rewind-pr-survey',
}

export enum FeedbackCategory {
  PeerReview = 'peer-review',
  PerformanceReview = 'performance-review',
  Snack = 'snack',
  General = 'general',
}

export enum ViewState {
  /** edit */
  default = 'edit',

  /** can modify questions and answers */
  edit = 'edit',

  /** move to a screen with additional settings*/
  reviewBeforeSending = 'review-before-sending',

  /** take a look on how feedback will look like to the receiver*/
  preview = 'preview',

  /** can modify questions, but cannot answer*/
  request = 'request',

  /** can't modify questions, have to answer*/
  respond = 'respond',

  /** generate report on all feedbacks with this templateId */
  generateReport = 'generate-report',
}

export enum FeedbackQuestionType {
  Text = 'text',
  Rating = 'rating',
}

export enum FeedbackType {
  Received = 'received',
  Given = 'given',
  HaveToRespond = 'have-to-respond',
  AwaitingResponse = 'awaiting-response',
}

export const FeedbackActionBtnLabel = {
  [FeedbackAction.request]: 'Request review',
  [FeedbackAction.give]: 'Ready to send',
  [FeedbackAction.takePRSurvey]: 'Complete survey',
  [FeedbackAction.rewindPRSurvey]: 'Use template for current PR cycle',
};

export const FeedbackCategoryLabel = {
  [FeedbackCategory.PeerReview]: 'Peer review',
  [FeedbackCategory.PerformanceReview]: 'Performance review',
  [FeedbackCategory.Snack]: 'Snack',
  [FeedbackCategory.General]: 'General review',
};

export enum AnonymousType {
  No = 'no',
  NameOnly = 'name-only',
  NameAndContent = 'name-and-content',
}
