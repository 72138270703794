import { createAction, createActionGroup, props } from '@ngrx/store';
import { Feedback } from 'src/core/interfaces/feedback.interface';
import { Option } from 'src/core/interfaces/option.interface';
import { NGRXFeedbackSource, NGRXFeedbackType } from './feedback-action.types';

export const FeedbackActions = createActionGroup({
  source: NGRXFeedbackSource.FeedbackSource,

  events: {
    [NGRXFeedbackType.setFeedbackFormData]: props<{ feedback: Feedback }>(),
    [NGRXFeedbackType.updateFeedbackFormData]: props<{
      field: any;
      value: any;
    }>(),
    [NGRXFeedbackType.setRecipients]: props<{ users: Option[] }>(),
    [NGRXFeedbackType.setRequestAbout]: props<{ aboutUser: Option }>(),
  },
});

export const removeAllFeedbacks = createAction(NGRXFeedbackType.removeAll);
